import { App } from 'vue'
import Intercom, { boot, update, trackEvent, shutdown } from '@intercom/messenger-js-sdk'

import { User } from '@/types/user.ts'
import { JsonObject } from '@/types/json-type.ts'
import { IAnalyticsHandler } from '@/types/analytics-handler-interface'

export class IntercomAnalytics implements IAnalyticsHandler {
  install(_app: App): void {
    Intercom({
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    })
  }

  identify(userId: string, data: User): void {
    boot({
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      user_id: userId,
      email: data.email,
      name: data.name,
    })
  }

  getRouteHandler = (url: string, _properties?: object): any => {
    this.trackPageView(url)
  }

  trackEvent(name: string, data: JsonObject, _userId?: string): void {
    trackEvent(name, data)
  }

  trackPageView(url: string | undefined, _properties?: object): void {
    update({ last_page: url })
  }

  hideWidget(): void {
    update({ hide_default_launcher: true })
  }

  logout(): void {
    shutdown()
  }
}
